<template>
    <!-- 서브페이지 내용 -->
    <div class="con-wrap">
        <CarrotTitle title="사보 당근농장 관리">
          
        </CarrotTitle>
        <div class="board">
            <div class="mt-40">
                <div>

                    <table class="table-row table-serach">
                        <tbody>
                            <tr>
                                <th width="180">발행년월</th>
                                <td>
                                    <select v-model="bbs.year" class="w-100px" v-on:change="bbs.doSearch" >
                                        <option v-for="year in bbs.years" :key="year" :value="year" >{{year}}</option>
                                    </select>
                                    년
                                    <select v-model="bbs.month" class="w-100px" v-on:change="bbs.doSearch">
                                        <option  v-for="m in bbs.months" :key="m" :value="m" >{{m}}</option>
                                    </select>
                                    월
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <div class="clear"></div>

                    <div class="mt-40 mb-20">                        
                        <button v-if="bbs.isAuth==true" @click="bbs.addNewsLetter" class="btn-default float-right">신규사보 등록</button>
                        <div class="clear"></div>
                    </div>

                    <table class="table-col">
                        <colgroup>
                            <col width="80">
                            <col width="120">
                            <col width="80">
                            <col>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>발행년도</th>
                                <th>발행월</th>
                                <th>제목</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(irow, i) in bbs.list" :key="i">
                                <td>{{ irow.topfix=='Y'?'-':irow.num }}</td>
                                <td>{{irow.year}}</td>
                                <td>{{irow.month}}</td>
                                <td class="txt-left" @click="bbs.showView(irow.idx)">
                                    <span class="btn-view">{{ irow.title }}</span>
                                    <span class="list-num ml-5" v-if="irow.cnt_cmt>0">({{ irow.cnt_cmt }})</span>
                                    <span class="ico-n ml-5" v-if="irow.is_new=='Y'">NEW</span>
                                </td>
                            </tr>
                            <tr v-if="bbs.total==0">
                                <td colspan="3">등록된 사보가 없습니다.</td>
                            </tr>
                        </tbody>
                    </table>
                    <CarrotPaging :total="bbs.total" :list_per_page="bbs.rows" v-model="bbs.page" @change="bbs.doSearch"></CarrotPaging>

                </div>
            </div>
        </div>
    </div>
    <!-- 서브페이지 내용 끝 -->
</template>

<script>
// @ is an alias to /src 
import { onMounted, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'vue-toastification';
import axios from '@/plugins/axios.js'
// import CarrotDatePicker from '@/components/common/CarrotDatePicker.vue'
import Swal from 'sweetalert2'
import { useStore } from 'vuex';


export default {
    layout: 'ResourceManagement',
    components: {
        // CarrotDatePicker
    },
    setup() {
        const router = useRouter();
        const toast  = useToast();
        const store  = useStore();

        const bbs = reactive({
            isAuth    : false,
            isMounted : false,
            isAll : false,
            selected : [],
            page : 1,
            rows : 20,
            years : [],
            months : [],
            year : "",
            month : "",
            preview : "",
            list : [], total : 0,

            checkAll : () => {
            if( bbs.isAll == true ){
                    for(let i=0; i<bbs.list.length; i++){
                        let irow = bbs.list[i];
                        bbs.selected.push(irow.idx);
                    }
                } else {
                    bbs.isAll    = false;
                    bbs.selected = [];
                }
            },

            doDelete : () => {
                if( bbs.selected.length <= 0 ){
                    Swal.fire({
                        title : '삭제',
                        text  : '삭제할 사보를 선택하세요.'
                    });
                    return;
                }
            },
            showView: (idx)=>{
                // location.href = "NoticeView/" + idx;
                router.push({
                name   : 'ResourceManagement-NewsLetterManagementView_tab1-idx',
                    params : { idx:idx }
                });
            },

            addNewsLetter : () => {
                sessionStorage.setItem('NewsLetterManagementAdd', JSON.stringify({
                    page : bbs.page,
                    rows : bbs.rows,
                    sdate     : bbs.sdate,
                    edate     : bbs.edate,
                }));
                router.push({
                    name : 'ResourceManagement-NewsLetterManagementAdd'
                });
            },

            doInit : () => {
                bbs.page = 1;
                bbs.isAll = false;
                bbs.selected = [];
                bbs.doSearch();
            },

            doSearch : () => {
                if( bbs.isMounted == false ) return;
                let params = {                    
                    page  : bbs.page,
                    year : bbs.year,
                    month : bbs.month,
                    preview : bbs.preview,
                    rows : bbs.rows,
                };
                axios.get('/rest/resourceManagement/getNewsLetterList', { params : params }).then((res) => {
                    if( res.data.err == 0 ){
                        bbs.list  = res.data.list;
                        bbs.total = res.data.total;

                        bbs.isAll = false;
                        bbs.selected = [];
                    } else {
                        if(res.data.err_msg) toast.error(res.data.err_msg);
                    }
                })

            },


        });


        onMounted(() => {
            // Mounted
            if( store.state.isSaboArchiving === false ) {
                router.go(-1);
                Swal.fire({
                    title : '사보 당근농장 관리',
                    text  : '접근 권한이 없습니다.'
                });
                return;
            }else{
                bbs.isAuth = true;
            }

            bbs.isMounted = true;

            let ss = sessionStorage.getItem('NewsLetterManagementList');
            if( ss ){
                ss = JSON.parse(ss);
                if( ss.page ) bbs.page = ss.page;
                if( ss.year ) bbs.year = ss.year;
                if( ss.month ) bbs.month = ss.month;
                if( ss.rows ) bbs.rows = ss.rows;
                if( ss.title     ) bbs.title     = ss.title;
                if( ss.writer     ) bbs.writer     = ss.writer;
            }

            bbs.years = [];
            let ndate = new Date();
            bbs.month = ndate.getMonth() + 1;

            bbs.years.push("");
            axios.get('/rest/resourceManagement/newsletterGetYears', {}).then((res) => {
                for(let i in res.data.year) {
                    bbs.years.push(res.data.year[i]);
                }
            })

            bbs.months.push("");    
            for(let i=1;i<=12;i++) {
                bbs.months.push(i);
            }
            bbs.year = "";
            bbs.month = "";

            bbs.doSearch();
        });

        return {bbs};
    }
}
</script>

<style lang="scss" scoped>
</style>